import React from 'react';

import { Layout } from '../components/layout';
import { SEO } from '../components/seo';
import { Content } from '../components/content';

export default function() {
  const title = 'Cirugía oral y maxilofacial';

  return (
    <Layout>
      <SEO title={title} />
      <h1>{title}</h1>
      <Content>
        <p>
          La cirugía oral y maxilofacial es la especialidad médica odontológica (estomatología) encargada del
          diagnóstico, la prevención y el tratamiento de las patologías bucales, faciales y cervicales.
        </p>
        <p>
          Por su diversidad esta especialidad y la consiguiente acción del cirujano abarcan numerosos procedimientos,
          incluyendo esto desde extracciones, hasta cirugías estéticas, oncológicas y reconstructivas. Entre los
          procedimientos usuales que se efectúan se encuentran :
        </p>

        <ul>
          <li>Extracción de muelas del juicio.</li>
          <li>Exodoncia de restos radiculares (raíces).</li>
          <li>
            Acceso a piezas supernumerarias incluidas (piezas dentarias no necesarias que crecen dentro del hueso).
          </li>
          <li>Resección de frenillos labiales.</li>
          <li>Colocación de implantes.</li>
          <li>Apicectomía (eliminar un tercio de la raíz del diente en caso de fracaso endodóntico entre otros).</li>
          <li>Eliminación de lesiones bucales y quistes.</li>
        </ul>

        <p>Además la especialidad también realiza cirugías que involucran de mayor complejidad, por ejemplo:</p>

        <ul>
          <li>Fracturas y heridas faciales (traumas muy frecuentes en niños y adolescentes).</li>
          <li>
            Cirugías por Malformaciones congénitas. En el ser humano que está dentro del útero materno existe la
            posibilidad de que la boca y cara no se formen de manera normal. Este es el caso de los niños que nacen con
            labio y paladar hendidos (FLAP: fisura labio alveolo palatina) que es la malformación facial congénita más
            conocida en nuestro medio. Existen además muchos síndromes que se manifiestan por falta de desarrollo de uno
            o ambos lados de la cara y cráneo, que deben ser tratados a tiempo por el cirujano maxilofacial.
          </li>
          <li>
            Cirugías por enfermedades de las glándulas salivales (producen saliva, para ayudar a la masticación y para
            mantener lubricada la boca durante el habla) como: parotiditis, cálculos, tumores benignos y malignos, etc.
          </li>
          <li>
            Patologías de la articulación temporomandibular (ATM- elemento anatómico que permite que la mandíbula se
            abra y se cierre, teniendo como punto de apoyo la base del cráneo), ya sea por traumatismos, estrés o
            patologías sistémicas. Muchas veces estas patologías se manifiestan por “ruidos” y dolores en oreja, cráneo
            y cuello.
          </li>
          <li>
            Cirugías ortognáticas: para tratar deformidades dento-cráneo-faciales como el prognatismo (mandíbula
            exageradamente grande), apertognatia (imposibilidad para cerrar la boca por contacto prematuro de molares o
            malformación de maxilar y mandíbula); y otras alteraciones que no pueden ser resueltas sin una cirugía.
          </li>
          <li>Cirugías estéticas como la mentoplastía y la rinoplastia.</li>
          <li>Cirugías reconstructivas de la cara.</li>
          <li>
            Cirugías por tumores benignos frecuentes como quistes, odontomas, fibromas, granulomas, etc. que aunque
            pueden ser asintomáticos (no producen molestias), el dejarlos puede ocasionar su agrandamiento o ser asiento
            de un tumor maligno.
          </li>
        </ul>

        <h2>Preguntas frecuentes</h2>
        <h3>¿Todas las muelas de juicio deben extraerse?</h3>
        <p>
          Es aconsejable, ya que pueden generar futuras patologías. Su valoración e indicación dependerá del buen
          diagnóstico del profesional odontólogo.
        </p>
        <h3>¿La cirugía oral es dolorosa?</h3>
        <p>
          No tiene que serlo. Las modernas técnicas de anestesia local permiten realizar todo tipo de intervenciones sin
          provocar sufrimiento al paciente.
        </p>
        <h3>¿Qué me pasará y qué cuidados debo tener luego de una cirugía oral?</h3>
        <ol>
          <li>
            CUIDADOS DE SU BOCA. Durante las primeras 4 horas después de la cirugía, póngase una bolsa de hielo sobre el
            área afectada. No se cepille los dientes en las áreas que tiene sutura. Si lo desea, puede limpiarla con una
            torunda de algodón sumergida en un colutorio bucal. Cepíllese normalmente los demas dientes.
          </li>
          <li>
            MALESTAR. Es natural sentir algo de dolor después de cualquier cirugía en la boca. Si se le recetó
            medicación, tómela según las instrucciones, y si se le recetan antibióticos tómelos, según se le haya
            indicado.
          </li>
          <li>
            INFLAMACIÓN. A veces, puede producirse un hematoma en la zona de la cirugía, que desaparecerá al cabo de 7
            días.
          </li>
          <li>
            HEMORRAGÍA. Después de la cirugía tendrá un poco de hemorragia. Evite escupir, enjuagar o chupar, porque
            esto alterará la cicatrización. Si persiste la hemorragia, doble una gasa y colóquela en la zona haciendo
            presión durante 30 minutos. No abra la boca ni hable. Si transcurridos los 30 minutos, continúa sangrando,
            repita la operación varias veces.
          </li>
          <li>
            DIETA. El día de la cirugía se recomienda comer solamente alimentos suaves y fríos. Después del primer día,
            manténgase en una dieta suave y blanda. No coma alimentos duros, con semillas o condimentados.
          </li>
          <li>
            DORMIR. Durante la primera noche después de la cirugía, deberá colocar dos almohadas para dormir, teniendo
            la cabeza más alta que los pies y de esta manera, evitará en gran parte la inflamación de la zona
            quirúrgica.
          </li>
          <li>
            NO SE DEBE. fumar puesto que el humo irrita los tejidos operados y retarda la cicatrización. Tampoco debe,
            durante las 24 horas siguientes a la cirugía, realizar esfuerzos físicos (levantar peso, deportes) estar en
            contacto con calor (secadores de pelo), así como ingerir bebidas alcohólicas.
          </li>
        </ol>
      </Content>
    </Layout>
  );
}
